import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './InstagramFeed.css';

const InstagramFeed = () => {
  const [media, setMedia] = useState([]);
  const instagramProfileUrl = "https://www.instagram.com/dptileandstone.ca/";

  useEffect(() => {
    const fetchMedia = async () => {
      const url = `https://graph.instagram.com/7389897431098515/media?access_token=IGQWROWFdRanZAEbzdURjhRN2NtOHVibUpwLXQtSDZAHRlBBVi1CN1NTVU04RENSOWhZAU3FlZAVd0SFpwQVV4Y0VKT085eDNGQ0JVUHpfNmtoLWg1MW94Q2F5ZAGZAJa2JYSEtzakNtY2U3aklfalAwN29aaXB3RXlDM1EZD&fields=id,media_type,media_url,username,timestamp`;
      try {
        const response = await axios.get(url);
        // Filter and slice the first 8 images for display, considering only 'IMAGE' or 'CAROUSEL_ALBUM'
        const filteredData = response.data.data.filter(item => item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM').slice(0, 8);
        setMedia(filteredData);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchMedia();
  }, []);

  return (
    <div className="instagram-feed-container" data-aos="fade-up" data-aos-duration="2000">
      <p className="instagram-id">
        <em>Follow </em>
        <a href={instagramProfileUrl} target="_blank" rel="noopener noreferrer" className="instagram-link">
          dptileandstone
        </a>
      </p>
      <div className="instagram-photos">
        {/* Display the first Instagram image */}
        {media[0] && (
          <div key={media[0].id} className="instagram-photo">
            <a href={instagramProfileUrl} target="_blank" rel="noopener noreferrer">
              <img src={media[0].media_url} alt={`Content from ${media[0].username}`} />
            </a>
          </div>
        )}
        
        {/* Custom photo placed as the second image */}
        <div className="custom-photo">
          <img src="https://i.postimg.cc/jqhmZCTd/Group-12.png" alt="Custom design element" />
        </div>

        {/* Display the remaining images from Instagram, starting from the second fetched image */}
        {media.slice(1).map((item) => (
          <div key={item.id} className="instagram-photo">
            <a href={instagramProfileUrl} target="_blank" rel="noopener noreferrer">
              <img src={item.media_url} alt={`Content from ${item.username}`} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstagramFeed;
