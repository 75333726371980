import React from "react"
import 'semantic-ui-css/semantic.min.css'
import '../styles/index.css'
import HomeBanner from "../components/homepage/homeBanner"
import Footer from "../components/common/footer";
import Locations from "../components/homepage/locations"
import HomePageLooks from "../components/homepage/homePageLooks"
import InstagramFeed from '../components/homepage/InstagramFeed';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Helmet } from "react-helmet";
class Homepage extends React.Component {

  componentDidMount() {
    this.aos = AOS
    this.aos.init()
  }

  componentDidUpdate() {
      this.aos.refresh()
  }
  render() {
    return (
      <>
      <Helmet>
          <title>Home - DP Tile and Stone</title>
          <meta name="description" content="Explore our wide range of tiles and stone surfaces to beautify your home or office space." />
          <meta name="keywords" content="tiles, home decor, stone surfaces, interior design" />
      </Helmet>
      <div className="homepage">
        <HomeBanner />
        <HomePageLooks />
        <Locations />
        {/* <Designerpick /> */}
        <InstagramFeed />
        <Footer />
      </div>
      </>
    )
  } 
}
export default Homepage
