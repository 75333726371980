import React from 'react';
import { Link } from "gatsby";
import './Locations.css';

const Locations = () => {
  const locations = [
    {
      title: 'Vancouver Showroom',
      description: '13520 Crestwood Pl #1, \n Richmond, BC V6V 2G2',
      imageUrl: 'https://i.postimg.cc/zfCnSW0x/2.jpg', // 假设为示例图片
    },
    {
      title: 'Victoria Office',
      description: 'Unit B, 643 David St,\n Victoria, BC V8T 2C9',
      imageUrl: 'https://i.postimg.cc/nhR8Vjvw/1.jpg', // 假设为同一示例图片
    },
  ];

  // 将 \n 替换为 <br /> 以在React中正确渲染换行
  const renderDescription = (description) => {
    return description.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ));
  };

  return (
    <div className="locations-wrapper" data-aos="fade-up" data-aos-duration="2000">
    <div className="locations-container">
      <div className="location-row">
        <img src={locations[0].imageUrl} alt="Vancouver Showroom" className="location-image location-image-vertical" />
        <div className="location-text">
          <p className="location-blurb">A Tour of Creativity</p>
          <p className="location-title"><em>Join us for a journey through design and craftsmanship  at our showrooms.</em></p>
          <p className="location-info"> {locations[0].title}</p>
          <p className="location-info">
            Address: {renderDescription(locations[0].description)}
          </p>
          <p className="location-info">Hours: Monday - Saturday   10am - 5pm</p>

        </div>
      </div>
      <div className="location-row reverse">
        <div className="location-text-2">
        <p className="location-title-2"><em>Shaping timeless elegance in every tile.</em></p>
          <p className="location-info">{locations[1].title}</p>
          <p className="location-info">
            Address: {renderDescription(locations[1].description)}
          </p>
          <p className="location-info">
          Phone: 250-590-5088</p>
          {/* <Link to="/contact" className="allLocationsButton">All Locations</Link> */}
        </div>
        <img src={locations[1].imageUrl} alt="Victoria Office" className="location-image location-image-horizontal" />
      </div>
    </div>
    </div>
  );
};

export default Locations;
