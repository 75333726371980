import React, { useState } from 'react';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import Modal from './Modal';
import '../../styles/homepagelooks.css';

const HomePageLooks = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        looks1image {
          fluid(maxWidth: 3000) {
            src
          }
        }
        looks1title
        looks1url
        looks2image {
          fluid(maxWidth: 3000) {
            src
          }
        }
        looks2title
        looks2url
        looks3image {
          fluid(maxWidth: 3000) {
            src
          }
        }
        looks3title
        looks3url
        looks4image {
          fluid(maxWidth: 3000) {
            src
          }
        }
        looks4title
        looks4url
        looks5image {
          fluid(maxWidth: 3000) {
            src
          }
        }
        looks5title
        looks5url
        looks6image {
          fluid(maxWidth: 3000) {
            src
          }
        }
        looks6url
        looks6title
      }
    }
  `);

  const options = [
    { title: 'Marble Look', url: '/looks/marble', img: 'https://i.postimg.cc/fWVJN30R/1.png' },
    { title: 'Stone Look', url: '/looks/stone', img: 'https://i.postimg.cc/YqcqBdHd/2.png' },
    { title: 'Concrete Look', url: '/looks/concrete', img: 'https://i.postimg.cc/Pxghh5BC/3.png' },
    { title: 'Wood Look', url: '/looks/wood', img: 'https://i.postimg.cc/FzXt8nLc/4.png' },
  ];

  const handleFirstItemClick = () => {
    if (window.innerWidth < 1024) {
      navigate('/all-porcelain');
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="looks" data-aos="fade-up" data-aos-duration="2000">
      <div className="looksHeaderContainer">
        <div className="looksHeaderLeft">
          <p className="looksHeaderBlurb">Where Technology Meets Art</p>
          <p className="looksHeader"><em>Producing exceptional tile designs that elevate your surroundings.</em></p>
        </div>
        <div className="looksHeaderRight">
          <div className="looksHeaderRightColumn">
            <p className="looksHeaderBlurb2">
            DP Tile and Stone combines cutting-edge technology with artistic design to bring you the highest quality tiles. Our extensive experience in porcelain tile manufacturing now informs our selection of products, ensuring we meet the diverse preferences and needs of our customers with exceptional service.
            </p>
            <p className="looksHeaderBlurb2">
              Our commitment to excellence ensures that every tile we have is a testament to beauty, durability, and functionality.<br /><br />
              Our carefully curated collections are designed to inspire and transform your spaces.
            </p>
          </div>
        </div>
      </div>

      <div className="looksContainer">
        {[...Array(6).keys()].map((i) => {
          const imageIndex = i + 1;
          const imageUrl = data.contentfulHomepage[`looks${imageIndex}image`]?.fluid?.src;
          const looksSlug = data.contentfulHomepage[`looks${imageIndex}url`];

          if (i === 0) {
            // Special handling for the first item
            return (
              <div key={i} className="looksItem" onClick={handleFirstItemClick}>
                <div className="looksLink">
                  <div className="imageContainer">
                    <img src={imageUrl} alt={`Look ${imageIndex}`} className="looksImage" />
                  </div>
                </div>
              </div>
            );
          }

          return (
            <div key={i} className="looksItem">
              <Link to={`/looks/${looksSlug}`} className="looksLink">
                <div className="imageContainer">
                  <img src={imageUrl} alt={`Look ${imageIndex}`} className="looksImage" />
                </div>
              </Link>
            </div>
          );
        })}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} options={options} />
    </div>
  );
};

export default HomePageLooks;
