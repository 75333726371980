import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import HeaderComponent from "../common/header";
import '../../styles/homepagebanner.css';

const HomeBanner = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // This ensures that window is accessed only on the client side
    const checkMobile = () => window.innerWidth <= 1024;
    setIsMobile(checkMobile());

    const handleResize = () => {
      setIsMobile(checkMobile());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query VideoQuery {
          contentfulHomepage {
            bannerTitle
            bannerSubtitle {
              bannerSubtitle
            }
            videoUrl
          }
        }
      `}
      render={data => {
        const { videoUrl } = data.contentfulHomepage;
        const bannerImageUrl = "https://i.postimg.cc/ncsb6tqN/Italgraniti-fjord-spa.jpg";

        return (
          <div className="homepage-banner">
            {isMobile ? (
              <img src={bannerImageUrl} alt="Banner Image" style={{ width: "100%" }} />
            ) : (
              <video autoPlay loop muted style={{ width: "100%" }}>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <HeaderComponent page="homepage"/>
          </div>
        );
      }}
    />
  );
};

export default HomeBanner;
