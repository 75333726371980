import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import './Modal.css';

const Modal = ({ isOpen, onClose, options }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  const handleOverlayClick = (event) => {
    if (event.target.className === "modal-overlay") {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content-2" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <p className="modal-title-2">Porcelain</p>
        <div>
            <Link to="/all-porcelain">
              <div className="option-link">Check out all Porcelain →</div>
            </Link>
          </div>
        <div className="modal-options">
          {options.map((option, index) => (
            <div key={index} className="modal-option">
              <Link to={option.url} className="option-link">
                <div className="option-image-container">
                  <img src={option.img} alt={option.title} className="option-image" />
                </div>
                <p className="option-title">{option.title}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
